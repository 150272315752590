import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaGlobe } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import Card from "../components/card"; // Make sure to update the path according to your project structure
import useCodeSnippet from "../hooks/useCodeSnippet";
import { snippet } from "../types";

const Dashboard = () => {
  const navigate = useNavigate();

  const { createSnippet, fetchSnippets, snippets } = useCodeSnippet();

  useEffect(() => {
    let accessToken = window.localStorage.getItem("accessToken");
    let refreshToken = window.localStorage.getItem("refreshToken");
    let user = window.localStorage.getItem("user") ?? null;

    if (!accessToken || !refreshToken || !user) {
      const params = new URLSearchParams(window.location.search);
      accessToken = params.get("access_token");
      refreshToken = params.get("refresh_token");
      user = params.get("user");

      if (accessToken && refreshToken && user) {
        const decodedUser = decodeURIComponent(user);
        const parsedUser = JSON.parse(decodedUser);

        // Store tokens and user object in local storage
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", JSON.stringify(parsedUser)); // Store the entire user object

        delete parsedUser.password;
        delete parsedUser.hashedRt;
      } else {
        navigate("/", { replace: true });
      }
      // Fetch snippets when the component mounts
      fetchSnippets();
    }
    fetchSnippets();
  }, []);

  const handleCardClick = (projectId: number) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user?.id) {
      const userId = user?.id; // Adjust this according to your user object structure
      navigate(`/project/${userId}/${projectId}`);
    } else {
      console.error("User ID not found in local storage.");
    }
  };

  return (
    <div className="flex flex-col w-screen h-screen justify-end items-start bg-lightBlue ">
      {/* Header */}
      <header className="flex justify-between items-center py-4 px-6 bg-white w-full">
        <div className="text-xl font-bold flex flex-row my-4">
          <img src="/yellow-arrow.png" alt="yellow-arrow" className="w-9 h-7" />
          <p className="text-2xl md:text-3xl">Fast Site</p>
        </div>
        <h1 className="text-xl font-semibold">Pages</h1>
        <div className="flex space-x-4">
          <FaGlobe className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue" />
          <IoPerson
            onClick={() => {
              navigate("/account", { replace: true });
            }}
            className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
          />
        </div>
      </header>

      {/* Content Area */}
      <div className="flex-1 my-4 flex flex-wrap justify-center gap-3 overflow-y-auto overflow-x-hidden">
        {snippets.map((snippet: snippet) => {
          return (
            <Card
              onClick={() => handleCardClick(snippet?.id)}
              isPopulated={true}
              status={snippet.isLive ? "live" : "draft"}
              title={snippet.pageName}
              output={snippet?.codeOutput}
            />
          );
        })}
        <div onClick={createSnippet}>
          <Card
            isPopulated={false}
            status={"draft"}
            title="first snippet"
            output={null}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
