import React, { useRef } from "react";
import { FaPlus } from "react-icons/fa"; // Importing plus sign icon

interface CardProps {
  output: string | null;
  status: string;
  title: string;
  isPopulated: boolean;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  output,
  status,
  title,
  isPopulated,
  onClick,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <div
      onClick={onClick}
      className={`flex flex-col items-center p-4 rounded-lg cursor-pointer  `} // Apply border style and color
    >
      {isPopulated ? (
        // Render content when isPopulated is true
        <>
          <iframe
            ref={iframeRef}
            className="preview w-full border-2 border-linkBlue rounded mb-4 bg-white cursor-pointer"
            title="Code Preview"
            srcDoc={output ?? ""}
            scrolling="no"
            style={{ height: "280px", pointerEvents: "auto" }}
          ></iframe>
          <div className="title font-semibold text-sm text-linkBlue">
            {status}
          </div>
          <div className="title font-semibold text-lg text-gray-700">
            {title}
          </div>
        </>
      ) : (
        // Render content when isPopulated is false
        <>
          <div className="flex flex-col items-center justify-center bg-white h-[280px] min-w-72 border-dashed border-linkBlue border-2 rounded-lg">
            <FaPlus className="text-blue-500 text-4xl mb-2" />
          </div>
          <div className="title font-semibold text-lg text-gray-700">-</div>
          <div className="title font-semibold text-lg text-linkBlue">
            Create New Page
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
