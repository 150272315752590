import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ToastContainer } from "react-toastify";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFoundPage from "./pages/notFound";
import LandingPage from "./pages/landingPage";
import ForgotPassword from "./pages/forgotPassword";
import Dashboard from "./pages/dashboard";
import MyAccount from "./pages/myAccount";
import AuthContextProvider from "./contexts/auth.context";
import ProtectedRoute from "./components/protected";
import Project from "./pages/project";
import PublicPreview from "./pages/preview";
import ResetPassword from "./pages/resetPassword";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/resetPassword/:token",
    element: <ResetPassword />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/project/:userId/:projectId",
    element: (
      <ProtectedRoute>
        <Project />
      </ProtectedRoute>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: "/account",
    element: (
      <ProtectedRoute>
        <MyAccount />
      </ProtectedRoute>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: "project/:uuid",
    element: <PublicPreview />,
    errorElement: <NotFoundPage />,
  },
]);

console.log("started.");

root.render(
  <React.StrictMode>
    <AuthContextProvider isAuthenticated={false}>
      <RouterProvider router={router} />
      <ToastContainer />
    </AuthContextProvider>
  </React.StrictMode>
);
