import React from "react";

const NotLive: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img
        src="/underConstruction.png"
        alt="under-construction"
        className=" w-2/5 "
      />
      <p className="text-2xl md:text-4xl text-black font-roboto">
        Page Under Construction.
      </p>
    </div>
  );
};

export default NotLive;
