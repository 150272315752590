import React from "react";
import { FaGlobe } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const MyAccount: React.FC = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-screen h-screen justify-end items-start bg-lightBlue ">
      {/* Header */}
      <header className="flex justify-between items-center py-4 px-6 bg-white w-full">
        <div className="text-xl font-bold flex flex-row my-4">
          <img
            src="/yellow-arrow.png"
            alt="yellow-arrow"
            className=" w-9 h-7"
          />
          <p className="text-2xl md:text-3xl">Fast Site</p>
        </div>
        <h1 className="text-xl font-semibold">Settings</h1>

        <div className="flex space-x-4">
          <FaGlobe
            onClick={() => {
              navigate("/dashboard", { replace: true });
            }}
            className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
          />
          <IoPerson className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue" />
        </div>
      </header>
      {/* page content */}
      <div className="flex flex-col justify-start items-center h-full w-full my-8 gap-8">
        <div className="flex flex-row justify-between gap-16 items-center w-1/4 ">
          <p className="text-lg font-roboto font-normal">User</p>
          <button
            onClick={signOut}
            className="bg-linkBlue text-white font-robot py-2 px-6 flex items-center rounded-lg"
          >
            Sign Out
            <FaArrowRightLong size={18} className="ml-2" />
          </button>
        </div>
        <hr className="w-1/4 border-t border-gray-400 my-4" />{" "}
        {/* Line separator */}
        <div className="flex flex-row justify-between gap-16 items-center w-1/4 ">
          <p className="text-lg font-roboto font-normal">Account</p>
          <button className="bg-transparent text-linkBlue font-robot py-2 px-6 flex items-center rounded-lg">
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
