import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: Props) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const refreshToken = window.localStorage.getItem("refreshToken");
  const userData = window.localStorage.getItem("user") ?? null;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData || !accessToken || !refreshToken) {
      navigate("/", { replace: true });
    }
  }, [userData, accessToken, refreshToken]);

  return <>{children}</>;
};

export default ProtectedRoute;
