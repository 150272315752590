// page aimed at providing a public preview of output of a code.
import React, { useEffect, useMemo, useRef, useState } from "react";
import useCodeSnippet from "../hooks/useCodeSnippet";
import { useParams } from "react-router-dom";
import NotLive from "../components/notLive";
import { toast } from "react-toastify";

const PublicPreview: React.FC = () => {
  const { fetchSnippetViaUUID } = useCodeSnippet();
  const [publicLink, setPublicLink] = useState("");
  const [preview, setPreview] = useState("");
  const [isLive, setIsLive] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeKey = useMemo(() => Math.random(), [preview]);

  const { uuid } = useParams();

  useEffect(() => {
    if (uuid) {
      fetchSnippetViaUUID(uuid)
        ?.then((res) => {
          setPublicLink(res?.data?.publicLink);
          setPreview(res?.data?.codeOutput);
          res?.data?.isLive ? setIsLive(true) : setIsLive(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const document = iframe.contentDocument;
      if (document) {
        const content = `${preview}`;
        document.open();
        document.write(content);
        document.close();
      }
    }
  }, [preview]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {isLive ? (
        <iframe
          title="unique"
          ref={iframeRef}
          key={iframeKey}
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <NotLive />
      )}
    </div>
  );
};

export default PublicPreview;
