import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface CreateCodeSnippetDto {
  pageName: string;
  html?: string;
  css?: string;
  js?: string;
}

interface UpdateCodeSnippetDto {
  id: number;
  html?: string;
  css?: string;
  js?: string;
  pageName?: string;
  codeOutput?: string;
  isLive?: boolean;
}

interface DeleteSnippetDto {
  snippetId: number;
}

const useCodeSnippet = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snippets, setSnippets] = useState([]);

  const createSnippet = async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = localStorage.getItem("accessToken");

      const data: CreateCodeSnippetDto = {
        pageName: "my draft page",
        html: `<h1>Edit Your Codepen </h1>
        <p>You can write your code here</p>`,
        css: `body {
              font-family: sans-serif;
              text-align: center;
              padding: 3rem;
              font-size: 1.125rem;
              line-height: 1.5;
              transition: all 725ms ease-in-out;
           }

            h1 {
                font-size: 2rem;
                font-weight: bolder;
                margin-bottom: 1rem;
            }

            p {
                margin-bottom: 1rem;
                color: tomato;
            }`,
        js: ``,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/codeProject/codeProject`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      toast.success("Code snippet created successfully !");

      // fetch projects again
      fetchSnippets();
      return response.data;
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err?.response?.data?.message}`);

      //   toast.error(err.response?.data?.message || "Something went wrong");
    }
  };

  const updateSnippet = async (data: UpdateCodeSnippetDto) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/codeProject/codeProject/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      toast.success("Project updated! changes saved");

      return response.data;
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err.response?.data?.message}`);
    }
  };

  const fetchSnippet = (projectId: number) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/codeProject/codeProject/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      toast.success("happy coding !");

      return response.then((res) => {
        return res?.data;
      });
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err.response?.data?.message}`);

      //   toast.error(err.response?.data?.message || "Something went wrong");
    }
  };

  const fetchSnippetViaUUID = (uuid: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/codeProject/codeProjectViaUUID/${uuid}`
      );
      setLoading(false);

      return response.then((res) => {
        return res?.data;
      });
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err.response?.data?.message}`);

      //   toast.error(err.response?.data?.message || "Something went wrong");
    }
  };

  const fetchSnippets = async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/codeProject/codeProjects`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setSnippets(response?.data?.data);
      setLoading(false);

      return response.data;
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err.response?.data?.message}`);

      //   toast.error(err.response?.data?.message || "Something went wrong");
    }
  };

  const deleteSnippet = async (data: DeleteSnippetDto) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/signup`
      );
      setLoading(false);
      toast.success("Signup successful!");

      return response.data;
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err.response?.data?.message}`);

      //   toast.error(err.response?.data?.message || "Something went wrong");
    }
  };

  return {
    loading,
    error,
    createSnippet,
    updateSnippet,
    fetchSnippet,
    fetchSnippetViaUUID,
    fetchSnippets,
    deleteSnippet,
    snippets,
  };
};

export default useCodeSnippet;
