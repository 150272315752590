import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  AuthContextType,
  AuthenticationDetails,
  AuthTokens,
  UserData,
} from "../types";
// context states
// Auth context for authentication throughout the entire application
/**
 * @AuthContext
 * @description
 * - The `AuthContext`is used to provide authentication data to the entire application. It contains default values for the state and functions to update the state.
 * an overview of the context is as follows:
 * - authLoaded: boolean - indicates whether the authentication data has been loaded from local storage.
 * - isAuthenticated: boolean - indicates whether the user is authenticated.
 * - authTokens: AuthTokens | null - contains the access and refresh tokens.
 * - userData: UserData | null - contains the user data.
 * @AuthContextProvider
 * @description `AuthContextProvider` - The `AuthContextProvider` is used to provide the AuthContext to the entire application. It contains the necessary state and functions to update the state. Its Overview is as follwos:
 * - it contains the  state and their setters (e.g setAuthLoaded ) and the `updateAuthenticationState` function.
 * - The `useEffect hook` is used to load the authentication data from local storage on start and also the auth tokens which are refresh token and access token.
 * - `updateAuthenticationState` function is used to update the state when the user logs in or logs out. mainly it manipulates the authTokens,user data and isAuthenticated state.
 */

type AuthContextProviderProps = {
  children: ReactNode;
  isAuthenticated?: boolean;
};

export const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,

  authLoaded: false,

  userData: null,

  authTokens: null,

  updateAuthenticationState: (_authDetails: AuthenticationDetails | null) => {},
});

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [authLoaded, setAuthLoaded] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [authTokens, setAuthTokens] = useState<AuthTokens | null>(null);

  const [userData, setUserData] = useState<UserData | null>(null);

  // const { addToast } = useToasts();
  const updateAuthenticationState = useCallback(
    (authDetails: AuthenticationDetails | null) => {
      if (authDetails) {
        console.log("auth tokens found?==>", authDetails.tokens);
        setAuthTokens(authDetails.tokens);
        setUserData(authDetails.user);
        console.log("auth user found?==>", authDetails.user);
        setIsAuthenticated(true);
      } else {
        setAuthTokens(null);
        setUserData(null);
        setIsAuthenticated(false);
      }
      setAuthLoaded(true);
    },
    []
  );

  useEffect(() => {
    // run on start to load data from local storage
    const accessToken = window.localStorage.getItem("accessToken");
    const refreshToken = window.localStorage.getItem("refreshToken");
    const userData = window.localStorage.getItem("user") ?? null;
    if (accessToken && refreshToken && userData) {
      setAuthTokens({
        access_token: accessToken,
        refresh_token: refreshToken,
      });
      setUserData(JSON.parse(userData));
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setAuthLoaded(true);
  }, [updateAuthenticationState]);

  // this function is responsible for setting tokes and user data when user logs in or logs out

  return (
    <AuthContext.Provider
      value={{
        authLoaded,
        isAuthenticated,
        authTokens,
        userData,
        updateAuthenticationState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
