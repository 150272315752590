import { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../contexts/auth.context";
import { useNavigate } from "react-router-dom";

interface SignupDto {
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
}

interface LoginDto {
  email: string;
  password: string;
}

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const { updateAuthenticationState } = useContext(AuthContext);

  const signup = async (data: SignupDto) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/signup`,
        data
      );
      setLoading(false);
      toast.success("Signup successful!");

      return response.data;
    } catch (err: any) {
      setLoading(false);
      //   setError(err.response?.data?.message || "Something went wrong");
      setError("Something went wrong");
      toast.error(`${err.response?.data?.message}`);

      //   toast.error(err.response?.data?.message || "Something went wrong");
    }
  };

  const login = async (data: LoginDto) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/signin`,
        data
      );
      setLoading(false);
      toast.success("Login successful!");
      //  store in local storage the response.data.data the tokens
      localStorage.setItem(
        "accessToken",
        response?.data?.data?.tokens?.access_token
      );
      localStorage.setItem(
        "refreshToken",
        response?.data?.data?.tokens?.refresh_token
      );

      //  store user data in context.
      localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));
      console.log("Updating authentication state.");
      console.log(response?.data?.data?.user);
      updateAuthenticationState(response?.data?.data);

      navigate("/dashboard", { replace: true });

      return response?.data;
    } catch (err: any) {
      setLoading(false);
      setError("Something went wrong");
      toast.error("Something went wrong");
      toast.error(`${err.response?.data?.message}`);
    }
  };

  const signInWithGoogle = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/googleAuth/redirect`
      );
      setLoading(false);

      localStorage.setItem(
        "accessToken",
        response?.data?.data?.tokens?.access_token
      );
      localStorage.setItem(
        "refreshToken",
        response?.data?.data?.tokens?.refresh_token
      );

      //  store user data in context.
      localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));

      updateAuthenticationState(response?.data?.data);

      return response.data;
    } catch (err: any) {
      setLoading(false);
      setError("Something went wrong");
      toast.error("Something went wrong");
      toast.error(`${err.response?.data?.message}`);
    }
  };

  const signOut = async () => {
    setLoading(true);
    setError(null);
    try {
      window.localStorage.setItem("user", "");
      window.localStorage.setItem("accessToken", "");
      window.localStorage.setItem("refreshToken", "");
      navigate("/", { replace: true });
    } catch (err: any) {
      setLoading(false);
      setError("Something went wrong");
      toast.error("Something went wrong");
      toast.error(`${err.response?.data?.message}`);
    }
  };

  const forgotPassword = async (email: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/forgotPassword/${email}`
      );
      setLoading(false);
      toast.success(
        "Password Reset Link Sent To Email. Please check your inbox or spam"
      );
    } catch (err: any) {
      setLoading(false);
      setError("Something went wrong");
      toast.error("Something went wrong");
      toast.error(`${err.response?.data?.message}`);
    }
  };

  const resetPassword = async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password/`,
        data
      );
      setLoading(false);
      toast.success("Password Reset Successful");

      setTimeout(() => {
        navigate("/", { replace: true });
      }, 2000); // 2000 milliseconds = 2 seconds

      navigate("/", { replace: true });
    } catch (err: any) {
      setLoading(false);
      setError("Something went wrong");
      toast.error("Something went wrong");
      toast.error(`${err.response?.data?.message}`);
    }
  };

  return {
    loading,
    error,
    signup,
    login,
    signInWithGoogle,
    signOut,
    forgotPassword,
    resetPassword,
  };
};

export default useAuth;
