import React, { ReactNode } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  buttonText: string;
  modalTitle: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  buttonText,
  modalTitle,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="relative w-full max-w-lg mx-4 bg-white rounded-lg shadow-lg">
        <button
          className="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          ×
        </button>
        <div className="p-6">
          <div className="mb-4">
            <h2 className="text-xl font-bold">{modalTitle}</h2>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
