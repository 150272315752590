import React from "react";

interface ButtonProps {
  text: string;
  Icon?: any;
  onClick?: () => {};
}

const Button: React.FC<ButtonProps> = ({ text, Icon, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="w-full max-w-2xl font-bold shadow-sm rounded-lg py-3 px-5 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline cursor-pointer"
    >
      {Icon && (
        <div className="bg-white p-2 rounded-full">
          <Icon className="w-4 h-4" />
        </div>
      )}
      <span className="ml-4">{text}</span>
    </button>
  );
};

export default Button;
