import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import { useParams } from "react-router-dom";

const ResetPassword: React.FC = () => {
  const { loading, error, resetPassword } = useAuth();
  const [password, setPassword] = useState<string>("");
  const { token } = useParams<{ token: string }>();

  const handleResetPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password) {
      const data = {
        token,
        password,
      };
      resetPassword(data);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen font-roboto gap-3">
      <div className="flex flex-col container w-1/2 justify-center items-center">
        <div className="flex flex-row gap-7 my-14">
          <img
            src="/red-arrow.png"
            alt="red-arrow"
            className="transform -translate-y-2 w-8 h-5"
          />
          <img
            src="/blue-arrow.png"
            alt="blue-arrow"
            className="transform translate-y-2 w-8 h-5"
          />
          <img
            src="/green-arrow.png"
            alt="green-arrow"
            className="transform translate-y-10 w-8 h-5"
          />
          <img
            src="/purple-arrow.png"
            alt="purple-arrow"
            className="transform translate-y-4 w-8 h-5"
          />
          <img
            src="/yellow-arrow.png"
            alt="yellow-arrow"
            className="transform translate-y-20 -translate-x-6 w-9 h-7"
          />
        </div>
        <img src="/fastsite-logo.png" alt="logo" />
      </div>
      <div className="flex flex-col justify-center items-center my-5 gap-4 px-5 w-1/3">
        <h1 className="text-2xl">Reset Password</h1>
        <h2 className="text-xl text-center">Please enter your new password</h2>
        <div className="flex flex-col w-full justify-center items-center">
          <form
            onSubmit={handleResetPassword}
            className="rounded-lg p-4 font-roboto w-full"
          >
            <div className="mb-4 flex flex-col gap-4 items-center justify-center w-full">
              <label htmlFor="password" className="text-lg mr-4">
                New Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-1/2 p-2 border border-borderBlue rounded-lg"
                required
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-4 text-white rounded-lg ${
                  loading ? "bg-gray-500 cursor-not-allowed" : "bg-blue-500"
                }`}
              >
                {loading ? "Submitting..." : "Change Password"}
              </button>
            </div>
            {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
